

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ImageResolver } from '@/services/Managers'

@Component
export default class LeaderboardsCard extends Vue {
  @Prop() player
  @Prop() unit
  @Prop() position
}
